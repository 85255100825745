<template>
  <v-card elevation="0" class="pa-4">
    <slot name="header"></slot>
    <div class="chart-wrapper">
      <canvas ref="myChart" :width="width" :height="height"></canvas>
    </div>
    <slot name="footer"></slot>
  </v-card>
</template>

<script>
import {Chart} from 'chart.js'
export default {
  name: 'BarChart',
  props: {
    data: {type: Object, default: () => ({})},
    type: {type: String, default: 'bar'},
    options: {type: Object, default: () => ({})},
    chartId: {type: [String, Number], default: 'bar-chart'},
    datasetIdKey: {type: String, default: 'label'},
    width: {type: [Number, String], default: 400},
    height: {type: [Number, String], default: 400},
    plugins: {type: Array, default: () => []}
  },
  mounted() {
    this.$nextTick(() => {
      const ctx = this.$refs.myChart
      this.chart = new Chart(ctx, {
        type: this.type,
        data: this.data,
        options: this.options,
        plugins: this.plugins
      })
    })
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy()
    }
  },
  watch: {
    data: {
      handler() {
        if (this.chart) {
          this.chart.data = this.data
          this.chart.update()
        }
      },
      deep: true
    }
  },
  data() {
    return {}
  },
  methods: {
    getCurrentChart() {
      return this.chart
    },
    toggleData(index) {
      this.chart.toggleDataVisibility(index)
      this.chart.update()
    },
    toggleDataset(index) {
      const {visible} = this.chart.getDatasetMeta(index)
      this.chart.setDatasetVisibility(index, !visible)
      this.chart.update()
    }
  }
}
</script>
